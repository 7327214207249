<template>
  <div class="px-3">
    <h2 class="text-white ml-4">
      Gestão de Fanbox:

      <img
        class="ml-2"
        :src="imgpath(selectedOwner)"
        height="60"
      >
    </h2>

    <section class="py-5">
      <div class="d-flex">
        <div
          v-for="(button, btnIdx) in buttons"
          :key="btnIdx"
        >
          <div class="d-flex flex-column align-items-center">
            <div
              class="iconsFixInterval py-5 px-y mx-4 col-sm-12 col-md-12 shadow-sm d-flex justify-content-center align-items-center"
              style="cursor: pointer; background-color: rgba(255, 255, 255, 0.3); border: 1px white solid;"
              @click="goToSelectedConfiguration(button.route)"
            >
              <i
                class="text-white"
                :class="button.icon"
                style="font-size: 48px"
              />
            </div>

            <div
              class="col-sm-12 col-md-11 text-center m-1 mt-1"
              style="max-width: 141px"
            >
              <label class="font-weight-bold text-white">
                {{ button.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* Config */
import { configDomain } from '@/global';

export default {
  name: 'Index',
  props: {
    selectedOwner: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    buttons: [
      {
        label: 'Configuração dos Boxes',
        icon: 'fa fa-box',
        route: 'Boxes',
      },
      {
        label: 'Estrutura do menu',
        icon: 'fa fa-tree',
        route: 'MenuTree',
      },
    ],
  }),
  methods: {
    goToSelectedConfiguration(route) {
      this.$router.push({
        name: route,
        params: { selectedOwner: this.selectedOwner },
      });
    },
    imgpath(id) {
      return `${configDomain}/sys/101/${id}/logo.png`;
    },
  },
};
</script>

<style>
.iconsFixInterval {
  height: 150px;
  border-radius: 18px;
  min-width: 150px;
  max-width: 150px;
}

.iconsFixInterval:hover {
  transform: scale(0.98);

  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);

  /* Lowering the shadow */
  background-color: rgba(255, 255, 255, 0.05) !important;
}
</style>
